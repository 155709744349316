import "./App.css";
import NavBar from "./components/nav-bar.component";
import Home from "./components/home.component";
import Experience from "./components/experience.component";
import Project from "./components/project.component";

function App() {
  return (
    <div
      className="App"
      style={{
        background: "#47454E",
        color: "#f1f1f1",
        display: "flex", // Corrected typo here
        flexDirection: "column",
      }}
    >
      <NavBar />
      <Home />
      <Experience />
      <Project />
    </div>
  );
}

export default App;
