import Typography from "@mui/material/Typography";

const Experience = () => {
  const experiences = [
    {
      title: "Software Engineer",
      company: "GOGOX",
      date: "Aug 2022 – Sept 2023",
      description: `Proficiency in AWS-based microservices architecture for web and mobile applications.
      Experience in the development of customized web application packages for internal use.
      Expertise in the management of user systems with multi-tenancy databases.
      Proficiency in the integration of third-party tools, including Firebase Cloud Messaging, for advanced application notifications.
      The establishment of efficient communication systems using Redis and Pusher.
      Effective collaboration with diverse, international team members.`,
      tag: [
        "AWS",
        "microservices",
        "Firebase Cloud Messaging",
        "ping-pong",
        "Redis",
        "Pusher",
      ],
    },
    {
      title: "Software Engineer",
      company: "Choco Up",
      date: "Jan 2022 – Jul 2022",
      description: `Third-party integration work encompassed sales platforms such as Shopify, Shopee, and Shoplazza, accounting platform Xero, and payment platform Stripe.
      Multi-factor authentication for the user management project was established using Twilio.
      Collaboration with Singapore branch team members involved contributing to project plans, sharing ideas in team meetings, and providing updates on deadlines, designs, and enhancements.
      Project requirements and specifications were evaluated and exceeded to meet client expectations.`,
      tag: [
        "Third-party Integration",
        "2FA",
        "Shopify",
        "Shopee",
        "Shoplazza",
        "Xero",
        "Stripe",
        "Twilio",
      ],
    },
    {
      title: "Javascript Developer",
      company: "Alpha Beans Limited",
      date: "Sept 2021 – Jan 2022",
      description: `Interactive digital maps were developed for popular shopping malls in Hong Kong, using ReactJS and an internal mapping tool.
      A sign-up website for NGOs in Hong Kong was constructed using AngularJS.
      Multiple functions and roles were managed concurrently to meet organizational expectations and deadlines.`,
      tag: ["Interactive Digital Maps ", "ReactJS", "AngularJS"],
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "20vh",
        textAlign: "left",
      }}
      id="Experiences"
    >
      <div
        style={{
          color: "#ffbb00",
          borderBottom: "1px solid",
          paddingBottom: "1%",
          marginTop: "2vh",
        }}
      >
        <Typography variant="h4" fontWeight={800}>
          Experiences
        </Typography>
      </div>
      {experiences.map((exp) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "1vh",
              gap: "8px",
            }}
          >
            <Typography variant="h6" fontWeight={800}>
              {exp.title}
            </Typography>
            <Typography variant="body2">{exp.company}</Typography>
            <Typography variant="body2">{exp.date}</Typography>
            <Typography variant="body1">{exp.description}</Typography>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {exp.tag.map((item) => {
                return (
                  <p
                    style={{
                      background: "#ffbb00",
                      color: "#4f4f51",
                      display: "inline-block",
                      borderRadius: "24px",
                      padding: "6px 12px 6px 12px",
                      fontWeight: 700,
                      marginRight: "6px",
                    }}
                  >
                    {item}
                  </p>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Experience;
