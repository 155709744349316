import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import LanguageIcon from "@mui/icons-material/Language";
import GitHubIcon from "@mui/icons-material/GitHub";

const projects = [
  {
    title: "Charity Finder Web",
    description:
      "Charity Finder is a web application that allows users to search for charities, view detailed information about each charity, and save their favorite charities.",
    tools: ["React", "Typescript", "Tailwind CSS", "Every.org API"],
    img: "https://user-images.githubusercontent.com/49020215/275408407-e3a0c760-226e-4ca4-9bba-b2c92ac8151f.png",
    git: "https://github.com/angel-wan/charity-finder-application#charity-finder-web-application",
    demo: "https://angel-wan-charity-finder.netlify.app/",
  },
  {
    title: "Markdown Previewer",
    description:
      "The Markdown Previewer is a web application that allows you to write and preview Markdown in real-time. It provides a user-friendly interface for composing and viewing Markdown documents with live rendering.",
    tools: ["React", "TypeScript", "styled-components", "react-markdown"],
    img: "https://user-images.githubusercontent.com/49020215/274386965-e08cf8fb-b78a-4fc6-a94e-841b3eabd1ba.png",
    git: "https://github.com/angel-wan/markdown-previewer",
    demo: "https://angel-wan-markdown-previewer.netlify.app/",
  },
  {
    title: "To-do List",
    description:
      "An application used to record to-do item. It allow users to add, update, delete, and view tasks.",
    tools: ["React", "Redux", "TypeScript", "Vite", "CSS"],
    img: "https://user-images.githubusercontent.com/49020215/281026025-3d6a1e3a-84fa-40c8-a0ba-91c250114ff4.png",
    git: "https://github.com/angel-wan/to-do-list",
    demo: "https://angel-wan-to-do-list.netlify.app/",
  },
];

const PorjectItem = ({ project }) => {
  return (
    <Card
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "60%",
        background: "transparent",
        color: "inherit",
        marginTop: "2vh",
        marginBottom: "10vh",
        boxShadow: "none",
      }}
    >
      <div style={{ height: "60%" }}>
        <CardMedia component="img" height="400" image={project.img} />
      </div>

      <div style={{ height: "40%", marginTop: "2vh" }}>
        <Typography variant="h6" fontWeight={800}>
          {project.title}
        </Typography>
        <Typography variant="body1">{project.description}</Typography>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {project.tools.map((tool) => {
            return (
              <p
                style={{
                  background: "#ffbb00",
                  color: "#4f4f51",
                  display: "inline-block",
                  borderRadius: "24px",
                  padding: "6px 12px 6px 12px",
                  fontWeight: 700,
                  marginRight: "6px",
                }}
              >
                {tool}
              </p>
            );
          })}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            style={{
              color: "#f1f1f1",
              width: "40%",
              fontWeight: 800,
              fontSize: "medium",
            }}
          >
            <a
              href={project.git}
              style={{ color: "#f1f1f1", textDecoration: "none" }}
            >
              View Code
            </a>
            <GitHubIcon style={{ marginLeft: "2px" }} fontSize="medium" />
          </Button>
          <Button
            style={{
              color: "#f1f1f1",
              width: "40%",
              fontWeight: 800,
              fontSize: "medium",
            }}
          >
            <a
              href={project.demo}
              style={{ color: "#f1f1f1", textDecoration: "none" }}
            >
              Live Demo
            </a>
            <LanguageIcon style={{ marginLeft: "2px" }} fontSize="medium" />
          </Button>
        </div>
      </div>
    </Card>
  );
};
const Project = () => {
  return (
    <div
      style={{
        marginTop: "20vh",
        textAlign: "left",
      }}
      id="Projects"
    >
      <div
        style={{
          color: "#ffbb00",
          borderBottom: "1px solid",
          paddingBottom: "1%",
          marginTop: "2vh",
        }}
      >
        <Typography variant="h4" fontWeight={800}>
          Projects
        </Typography>
      </div>
      {projects.map((project) => {
        return <PorjectItem project={project} />;
      })}
    </div>
  );
};

export default Project;
