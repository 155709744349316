import Typography from "@mui/material/Typography";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";

const Home = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "26vh",
        gap: "5vh",
        alignItems: "center",
      }}
      className="home"
      id="About"
    >
      <div className="home-description">
        <Typography variant="h3">{`Hi, Here is Angel Wan`}</Typography>
        <Typography variant="h6" color={"#808080"}>
          Welcome to my web space! I'm a seasoned software engineer, passionate
          about developing applications and solving complex technical
          challenges. With expertise in both frontend and backend development, I
          thrive in the dynamic world of software engineering. Explore my work
          and insights as you embark on your digital journey. Thanks for
          visiting!
        </Typography>
      </div>

      {/* <div style={{ textAlign: "right" }}></div> */}
      <div style={{ display: "flex", gap: "16px" }}>
        <a
          href="https://www.linkedin.com/in/angel-wan-ys"
          style={{ color: "white" }}
        >
          <LinkedInIcon fontSize="large" />
        </a>
        <a href="https://github.com/angel-wan" style={{ color: "white" }}>
          <GitHubIcon fontSize="large" />
        </a>
        <a href="mailto:angelwys9926@gmail.com" style={{ color: "white" }}>
          <EmailIcon fontSize="large" />
        </a>
      </div>
      <div style={{ marginTop: "18vh" }}>
        <img
          src="https://skillicons.dev/icons?i=js,ts,nodejs,nestjs,express,python,java"
          alt=""
        />
        <img
          src="https://skillicons.dev/icons?i=react,angular,tailwind,html,css"
          alt=""
        />
        <img src="https://skillicons.dev/icons?i=aws,kafka,docker,gcp" alt="" />
        <img
          src="https://skillicons.dev/icons?i=postgres,mongodb,firebase,mysql"
          alt=""
        />
      </div>
    </div>
  );
};

export default Home;
